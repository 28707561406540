// Matristransponering
function transpose(matrix) {
    return matrix[0].map((_, colIndex) => matrix.map(row => row[colIndex]));
}

// Matrismultiplikation
function multiply(matrixA, matrixB) {
    const result = Array(matrixA.length).fill(0).map(() => Array(matrixB[0].length).fill(0));

    for (let i = 0; i < matrixA.length; i++) {
        for (let j = 0; j < matrixB[0].length; j++) {
            for (let k = 0; k < matrixA[0].length; k++) {
                result[i][j] += matrixA[i][k] * matrixB[k][j];
            }
        }
    }
    return result;
}

// Invertera matris
function inverse(matrix) {
    const size = matrix.length;
    const augmentedMatrix = matrix.map((row, rowIndex) => [...row, ...Array(size).fill(0).map((_, colIndex) => rowIndex === colIndex ? 1 : 0)]);

    for (let i = 0; i < size; i++) {
        let maxElementIndex = i;
        for (let j = i + 1; j < size; j++) {
            if (Math.abs(augmentedMatrix[j][i]) > Math.abs(augmentedMatrix[maxElementIndex][i])) {
                maxElementIndex = j;
            }
        }

        if (augmentedMatrix[maxElementIndex][i] === 0) {
            throw new Error("Matrix is singular and cannot be inverted");
        }

        [augmentedMatrix[i], augmentedMatrix[maxElementIndex]] = [augmentedMatrix[maxElementIndex], augmentedMatrix[i]];

        for (let j = i + 1; j < size * 2; j++) {
            augmentedMatrix[i][j] /= augmentedMatrix[i][i];
        }

        for (let j = 0; j < size; j++) {
            if (j !== i) {
                const factor = augmentedMatrix[j][i];
                for (let k = i; k < size * 2; k++) {
                    augmentedMatrix[j][k] -= factor * augmentedMatrix[i][k];
                }
            }
        }
    }

    return augmentedMatrix.map(row => row.slice(size));
}

// Enkla linjära regressioner för ålder och år i organisationen
function simpleLinearRegression(X, y) {
    const XT = transpose(X);
    const XT_X = multiply(XT, X);
    const XT_X_inv = inverse(XT_X);
    const XT_y = multiply(XT, y);
    const beta = multiply(XT_X_inv, XT_y);

    return beta;
}

// Förbered datan för enkel regression (anpassad för att bara ha en variabel)
const prepareDataSingle = (employees, feature) => {
    const X = employees.map(emp => [1, emp[feature]]); // 1 för intercept, 'feature' är 'age' eller 'expNoJitter'
    const y = employees.map(emp => [emp.y]); // y är aktuell lön
    return { X, y };
};

// Förutsäg lön baserat på koefficienter och en given variabel
const predictSimpleSalary = (coefficients, featureValue) => {
    const intercept = coefficients[0][0];
    const beta = coefficients[1][0];

    return intercept + beta * featureValue; // Prediktion baserat på intercept och koefficient för variabeln
};

const generateRegressionLine = (coefficients, XValues) => {
    const intercept = coefficients[0][0];
    const beta = coefficients[1][0];

    // Skapa en array med punkter som motsvarar regressionslinjen
    const regressionLine = XValues.map(X => ({
        X: X,  // Oberoende variabel (t.ex. ålder eller erfarenhet)
        y: intercept + beta * X  // Predikterad lön (beroende variabel)
    }));

    // Returnera regressionslinjen och själva ekvationen
    return {
        equation: `y = ${intercept.toFixed(2)} + ${beta.toFixed(2)} * X`,
        line: regressionLine
    };
};

// Huvudfunktion för analysen
const RegressionAnalysis = (employees) => {
    // Vikter
    const weightAge = 0.5;  // Vikt för ålder
    const weightExp = 0.5;  // Vikt för erfarenhet

    // Initial thresholds
    let limit = 0.05; // 5%
    let limitMinus = -0.05; // -5%

    // Kontrollera vikterna
    if (weightAge + weightExp !== 1) {
        throw new Error("Vikterna för ålder och erfarenhet måste summera till 1.");
    }

    // Räkna ut regressionskoefficienterna för ålder
    const { X: ageX, y: ageY } = prepareDataSingle(employees, 'age');
    const ageCoefficients = simpleLinearRegression(ageX, ageY);

    // Räkna ut regressionskoefficienterna för erfarenhet
    const { X: expX, y: expY } = prepareDataSingle(employees, 'expNoJitter');
    const expCoefficients = simpleLinearRegression(expX, expY);

    // Generera regressionslinjer
    const ageValues = employees.map(emp => emp.age);  // Samla alla åldervärden
    const expValues = employees.map(emp => emp.expNoJitter);  // Samla alla erfarenhetsvärden
 
    const ageRegression = generateRegressionLine(ageCoefficients, ageValues);
    const expRegression = generateRegressionLine(expCoefficients, expValues); 

    // Arrays to hold suggestions
    const suggestionsUnderpaid = [];
    const suggestionsOverpaid = [];
    
    // Iterera över varje medarbetare och beräkna differenserna
    employees.forEach(emp => {
        // Förväntad lön enligt ålder och erfarenhet
        const expectedSalaryAge = predictSimpleSalary(ageCoefficients, emp.age);
        const expectedSalaryExp = predictSimpleSalary(expCoefficients, emp.expNoJitter);

        // Beräkna differenser: Positivt om de tjänar mindre än förväntat (de borde tjäna mer)
        const differenceAge = expectedSalaryAge - emp.y;  // Vänd på differensen
        const differenceExp = expectedSalaryExp - emp.y;  // Vänd på differensen

        // Beräkna total differens med vikter
        let totalDifference = (weightAge * differenceAge) + (weightExp * differenceExp);

        emp.adjustment = Math.round(totalDifference); // Add the adjustment property
        emp.expectedSalary = emp.y + totalDifference;

        // Kontrollera om skillnaden mellan expectedSalary och aktuell lön är > 5% eller < -5%
        const salaryDifference = emp.expectedSalary - emp.y;
        const percentageDifference = (salaryDifference / emp.y); 
        
        if (percentageDifference < limitMinus && salaryDifference < 0) {
            emp.ai = `${Math.abs(emp.adjustment)} kr högre lön än väntat`; // Add new 'ai' property. Math abs turns minus into plus, so the adjustment is minus but we want it to show like plus
            suggestionsOverpaid.push(emp); // Lägg till i suggestionsOverpaid 
        } else if (percentageDifference > limit && salaryDifference > 0) {
            emp.ai = `${Math.abs(emp.adjustment)} kr lägre lön än väntat`; // Add new 'ai' property. Math abs turns minus into plus, so the adjustment is minus but we want it to show like plus
            suggestionsUnderpaid.push(emp); // Lägg till i suggestionsUnderpaid
        }

        // Skriv ut resultatet för varje medarbetare
        console.log(`Medarbetare: ${emp.fullName}`);
        console.log(`Aktuell lön: ${emp.y}`);
        console.log(`Förväntad lön (ålder): ${expectedSalaryAge}, Differens (ålder): ${differenceAge}`);
        console.log(`Förväntad lön (erfarenhet): ${expectedSalaryExp}, Differens (erfarenhet): ${differenceExp}`);
        console.log(`Total differens (summa av båda): ${totalDifference}`);
        console.log("salaryDifference: " + salaryDifference);
        console.log("percentageDifference: " + percentageDifference);
    });

    // Dynamisk tröskeljustering
    while (suggestionsUnderpaid.concat(suggestionsOverpaid).length > 10) {
        // Justera trösklar om fler än 10
        limit += 0.02; // Öka med 2%
        limitMinus -= 0.02; // Minska med 2%

        // Rensa listorna och filtrera igen med den nya gränsen
        suggestionsUnderpaid.length = 0; 
        suggestionsOverpaid.length = 0;

        employees.forEach(emp => {
            // Kontrollera med nya gränser
            const salaryDifference = emp.expectedSalary - emp.y;
            const percentageDifference = (salaryDifference / emp.y); 
            
            if (percentageDifference < limitMinus && salaryDifference < 0) {
                emp.ai = `${Math.abs(emp.adjustment)} kr högre lön än väntat`; 
                suggestionsOverpaid.push(emp); 
            } else if (percentageDifference > limit && salaryDifference > 0) {
                emp.ai = `${Math.abs(emp.adjustment)} kr lägre lön än väntat`; 
                suggestionsUnderpaid.push(emp); 
            }
        });
    }

    return [
        suggestionsUnderpaid, 
        suggestionsOverpaid,
        ageRegression,
        expRegression,
    ];
};

// Exportera RegressionAnalysis
export { RegressionAnalysis };


// Förbättringar:
// För många markeras. Kika munkedals kommun Lärare grundskola tidigare år, för många som markeras. sätta någon gräns eller avgöra om någon faktor inte ska påverka så mkt, tror dessa beror på år i org tex. Så om fler än ett visst antal procent markeras kan den faktorns vikt sänkas tex.
// Ta hänsyn till lönediffen och fokusera på kvinnor eller män för att få ner den?


// KANSKE MEN EJ PRIO:
// Regressionslinje finns nu men visas som punkter, det går bra för min testning men om ska visa den live (vilket jag troligtvis inte ska göra) så behöver den vara en linje.


// OBS, originalet verkar hantera regressionslinjen ganska bra, så behåll som det är nu men annars kan nedan göras.
// Hantera extramvärden så att regressionslinjen inte blir för skev. Gör såhär 
// Prova något av nedan eller en kombination av olika:
/* 
- Remove outliers: Remove data points that deviate too much from the trend
- Transform data: Apply transformations like logarithms to reduce the impact of extreme values.
- Cap extreme values: Cap values that are too high or low at a reasonable percentile.
- Robust regression: Use models that are less sensitive to outliers.

Känn av slopen. Om rak eller lutar nedåt, ange ingen påverkan
*/